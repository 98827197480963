const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/react/default'
    basename: '',
    defaultPath: '/dashboard/default',
    fontFamily: `'Helvetica', sans-serif`,
    borderRadius: 12,
    typoFontSize: 12,
    textfieldFontSize: 12,
    buttonFontSize: 14,
    presetColor: 'blueOrange'
};

export default config;
