// assets
import { IconReport } from '@tabler/icons';
import admChild from './AdmChild';

// constant
const icons = { IconReport };

const adminGrp = {
    id: `adminGrp-${Math.random()}`,
    title: 'Admin',
    type: 'group',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: admChild,
    role: process.env.REACT_APP_RL_ADMGRP
};

export default adminGrp;