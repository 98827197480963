import { IconDeviceDesktopAnalytics } from '@tabler/icons';

const icons = { IconDeviceDesktopAnalytics };

const serviceHealthMenu = {
    id: `serviceHealth-${Math.random()}`,
    title: 'Service Health',
    type: 'item',
    icon: icons.IconDeviceDesktopAnalytics,
    breadcrumbs: false,
    url: '/service-health',
    children: [],
    role: process.env.REACT_APP_RL_SERVICE_HEALTH_MENU
};

export default serviceHealthMenu;