const voucher = {
    id: `voucher-${Math.random()}`,
    title: 'Voucher',
    type: 'item',
    url: `/rewards/voucher`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_RWDVCH
};

export default voucher;
