import { SET_HOLDER } from "./actions";

export const initialState = {
    payload: {},
};

const dynamicReducerFactory = () => (state = initialState, action) => {
    const allCases = {
        [SET_HOLDER]: (state, { payload }) => ({ ...state, payload }),
    };

    if (Object.prototype.hasOwnProperty.call(allCases, action.type)) {
        return allCases[action.type](state, action);
    }

    return state;
};

const dynamicReducer = dynamicReducerFactory();
export default dynamicReducer;