import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import { element } from 'prop-types';
import Loadable from 'ui-component/Loadable';

const Generate = Loadable(lazy(() => import('views/report/generate')));
const BlankPage = Loadable(lazy(() => import('views/blank-page')));

// ==============================|| DEFAULT ROUTING ||============================== //

const ReportRoutes = {
    path: '/report',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_GNT}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/report/generate',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_GENCOL}`}>
                    <Generate key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/report/upload',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_GENCOL}`}>
                    <BlankPage key={Math.random()} />
                </PrivateRoutes>
            ]
        },
    ]
};

export default ReportRoutes;