import { SET_AUTOLOAD_ACTION, SET_AUTOLOAD_INTERVAL } from "./actions";

const initialState = {
  autoloadAction: null,
  autoloadInterval: 10000
};

// ==============================|| POP ALERT REDUCER ||============================== //
const autoloadReducer = (state = initialState, action) => {
  switch (action.type) {
      case SET_AUTOLOAD_ACTION:
          return {
          ...state,
          autoloadAction: action.payload,
        };
        case SET_AUTOLOAD_INTERVAL:
          return {
          ...state,
          autoloadInterval: action.payload,
        };
      default:
          return state;
  }
};

export default autoloadReducer;