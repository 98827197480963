// assets
import { IconReport } from '@tabler/icons';
import prtChild from './PrtChild';

// constant
const icons = { IconReport };

const partnerGrp = {
    id: `partnerCol-${Math.random()}`,
    title: 'Partner Profile',
    type: 'group',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: prtChild,
    role: process.env.REACT_APP_RL_PRTPRFGRP
};

export default partnerGrp;