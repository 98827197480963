// material-ui
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import keycloak from 'services/keycloak';
import { Navigate, useNavigate } from 'react-router';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const Page404 = () => {
    const toNavigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            if (keycloak.authenticated) {
                keycloak.logout(toNavigate("/pages/login/cloak"));
            }
        }, 3000);
    }, []);
    return (
        <MainCard title="Page Not Found/Accessible">
            <Typography variant="body2">
                The Page your trying to access is not found or accessible.
            </Typography>
            {
                keycloak.authenticated ?
                <Typography fontWeight="bold">Your account is still login wait for 5sec. to automatically logout</Typography>
                :
                <></>
            }
        </MainCard>
    );
};

export default Page404;
