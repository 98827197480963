const partnerPrefunding = {
    id: `opFund-${Math.random()}`,
    title: 'Partner Prefunding',
    type: 'item',
    url: `/financial/partnerPrefund`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_FINANCIAL_MENU_CHILD_PARTNERPREFUND
};

export default partnerPrefunding;