import PrivateRoutes from '../PrivateRoutes';
import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from 'ui-component/Loadable';

const ServiceHealth = Loadable(lazy(() => import('views/service-health')));

const ServiceHealthRoutes = {
    path: '/service-health',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_SH}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/service-health',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_SERVICE_HEALTH_MENU}`}>
                    <ServiceHealth key={Math.random()} />
                </PrivateRoutes>
            ]
        }
    ]
};

export default ServiceHealthRoutes;