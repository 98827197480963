const opFund = {
    id: `opFund-${Math.random()}`,
    title: 'OP Fund',
    type: 'item',
    url: `/financial/opFund`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_FINANCIAL_MENU_CHILD_OPFUND
};

export default opFund;