import { Button, Dialog, DialogContent, Slide } from "@mui/material";
import { useEffect, useState, forwardRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { hideAlert } from "store/action/popAlert";
import DefaultAlert from "./alert-type/DefaultAlert";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const AlertMessage = () => {
    const dispatch = useDispatch();
    const { pop, amount, transactionDate, message, title, action, type } = useSelector(state => state.alertReducer);
    const handleClose = () => {
        dispatch(hideAlert());
    };

    const allowedHideAlert = ['CLOSE', 'OK', 'CANCEL', 'BACK'];
    const processAction = (myAction) => {
        if (myAction) {
            myAction?.map(data => {
                if (allowedHideAlert.includes(data?.name) && data?.value === true) {
                    handleClose();
                }
            });
        }
    };

    useEffect(() => {
        let isMounted = true;
        processAction(action);
        return () => {
            isMounted = false;
    };
    }, [pop, amount, transactionDate, message, title, action, type]);

    return (
        <Dialog
            TransitionComponent={Transition}
            maxWidth="xs"
            open={pop}
            onClose={() => { if (action === null) dispatch(hideAlert()); }}
        >
        {type === 'default' && (
            <DefaultAlert
                pop={pop}
                amount={amount}
                transactionDate={transactionDate}
                message={message}
                title={title}
                action={action}
                type={type}
            />
        )}
        </Dialog>
    );
};

export default AlertMessage;