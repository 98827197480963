import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import Loadable from 'ui-component/Loadable';
// sample page routing
const BlankPage = Loadable(lazy(() => import('views/blank-page')));
const BillsPayment = Loadable(lazy(() => import('views/transaction-report/bills-payment')));
const OnlinePayment = Loadable(lazy(() => import('views/transaction-report/online-payment')));
const TopUp = Loadable(lazy(() => import('views/transaction-report/top-up')));
const Ordering = Loadable(lazy(() => import('views/transaction-report/ordering')));
const Disbursement = Loadable(lazy(() => import('views/transaction-report/disbursement')));
const CashIn = Loadable(lazy(() => import('views/transaction-report/cashin')));

// ==============================|| TRANSACTION REPORT ROUTING ||============================== //

const TransactionReportRoutes = {
    path: '/transaction',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_TRN}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/transaction',
            element: [
                <></>
            ]
        },
        {
            path: '/transaction/online',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_TRANSONLINE}`}>
                    <OnlinePayment />
                </PrivateRoutes>
            ]
        },
        {
            path: '/transaction/ordering',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_TRANSORDERING}`}>
                    <Ordering />
                </PrivateRoutes>
            ]
        },
        {
            path: '/transaction/bills',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_TRANSBILLS}`}>
                    <BillsPayment />
                </PrivateRoutes>
            ]
        },
        {
            path: '/transaction/disbursement',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_TRANSDISBURSE}`}>
                    <Disbursement key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/transaction/cashin',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_TRANSCASHIN}`}>
                    <CashIn key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/transaction/top',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_TRANSTOP}`}>
                    <TopUp />
                </PrivateRoutes>
            ]
        },
    ]
};

export default TransactionReportRoutes;