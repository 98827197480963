// assets
import { IconReport } from '@tabler/icons';
import stlntChild from './StlntChild';

// constant
const icons = { IconReport };

const settlementGrp = {
    id: `settlementGrp-${Math.random()}`,
    title: 'Settlement',
    type: 'group',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: stlntChild,
    role: process.env.REACT_APP_RL_SETTLEMENTGRP
};

export default settlementGrp;
