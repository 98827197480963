const generate = {
    id: `generate-${Math.random()}`,
    title: 'Generate',
    type: 'item',
    url: `/report/generate`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_GENCOL
};

export default generate;