import jwtDecode from 'jwt-decode';

const verifyToken = (data) => {
    try {
        if (jwtDecode(data).iss !== process.env.REACT_APP_ISSUER && jwtDecode(data).azp !== process.env.REACT_APP_PART) {
            return false;
        }
        if (jwtDecode(data).exp < Date.now() / 1000) {
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
};
const verifyAccount = (data) => {
    try {
        return jwtDecode(data).user_account === process.env.REACT_APP_ACC;
    } catch (error) {
        return false;
    }
};
const hasRole = (data, role) => {
    try {
        const availRoles = jwtDecode(data).realm_access.roles;
        return availRoles.some(defRole => {
            if (defRole.toLowerCase() === role.toLowerCase()) {
                return true;
            }
            return false;
        });
    } catch (error) {
        return false;
    }
};

const hasGrp = (data, grp) => {
    try {
        const availGrp = jwtDecode(data).group;
        return availGrp.some(defGrp => {
            if (defGrp.toLowerCase() === grp.toLowerCase()) {
                return true;
            }
            return false;
        });
    } catch (error) {
        return false;
    }
};

const authByRoles = (data, roles) => {
    const isAuthorized = () => {
        if (roles) {
            return roles.some(r => {
                const realm = hasRole(data, r);
                return realm;
            });
        }
        return false;
    };

    return isAuthorized();
};

const authByGroup = (data, grp) => {
    const isAuthorized = () => {
        if (grp) {
            return grp.some(r => {
                const realm = hasGrp(data, r);
                return realm;
            });
        }
        return false;
    };

    return isAuthorized();
};

const checker = {
    verifyAccount,
    verifyToken,
    authByRoles,
    authByGroup
};

export default checker;