import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import { element } from 'prop-types';
import Loadable from 'ui-component/Loadable';

const BlankPage = Loadable(lazy(() => import('views/blank-page')));
const Users = Loadable(lazy(() => import('views/maintenance/Users')));

// ==============================|| DEFAULT ROUTING ||============================== //

const MaintenanceRoutes = {
    path: '/maintenance',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_MNT}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/maintenance',
            element: [
                <></>
            ]
        },
        {
            path: '/maintenance/users',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_MNTUSR}`}>
                    <Users key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/maintenance/audit',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_MNTAUT}`}>
                    <BlankPage key={Math.random()} />
                </PrivateRoutes>
            ]
        },
    ]
};

export default MaintenanceRoutes;