import { useKeycloak } from '@react-keycloak/web';
import { useAuth } from 'hooks/useAuth';
import { Navigate } from 'react-router';
import checker from 'utils/checker';
import { useNavigate } from 'react-router-dom';

const PrivateRoutes = ({ children, accessRole, accessGRole }) => {
    const { keycloak } = useKeycloak();
    const navigateInUse = useNavigate();

    const isLoggedIn = keycloak.authenticated;

    if (isLoggedIn) {
        // if (!checker.verifyToken(useAuth().token)) {
        //     localStorage.clear();
        //     useAuth().logout();
        // }

        try {
            if (localStorage.getItem("out")) useAuth().logout();
        } finally {
            if (accessRole) {
                if (!checker.authByRoles(useAuth().token, [accessRole])) {
                    console.log("Not Accessible");
                    // keycloak.logout();
                    navigateInUse('/404');
                    <Navigate to="/404" />;
                } else {
                    console.log("Accessible");
                }
            }
        }
    }

    return isLoggedIn ? children : <Navigate to="/pages/login/cloak" />;
};

export default PrivateRoutes;