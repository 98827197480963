// action - state management
import * as actionTypes from './actions';

export const initialState = {
    productCombo: [],
    channelCombo: [],
    statusCombo: [],
    categoryCombo: [],
    adminBranchCombo: [],
    adminProductCombo: [],
    adminRateCombo: [],
    adminVatCombo: [],
    adminTaxCombo: [],
    adminCostFeeCombo: [],
    adminServiceCombo: [],
    adminAggrPtrCombo: [],
    adminCategoryCombo: [],
    adminPtrTypeCombo: [],
    billsProductCombo: [],
    billsChannelCombo: [],
    onlinePartnerCombo: [],
    onlineBranchCombo: [],
    topupPartnerCombo: [],
    topupBranchCombo: [],
    topupProviderCombo: [],
    cashinProductCombo: [],
};

// ==============================|| CLOAK REDUCER ||============================== //

const comboboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PRODUCT_COMBO:
            return {
                ...state,
                isOpen: [action.productCombo]
            };
        case actionTypes.SET_CHANNEL_COMBO:
            return {
                ...state,
                isOpen: [action.channelCombo]
            };
        case actionTypes.SET_STATUS_COMBO:
            return {
                ...state,
                statusCombo: [action.statusCombo]
            };
        case actionTypes.SET_CATEGORY_COMBO:
            return {
                ...state,
                isOpen: [action.categoryCombo]
            };
        case actionTypes.SET_ADMIN_BRANCH_COMBO:
            return {
                ...state,
                adminBranchCombo: [action.adminBranchCombo]
            };
        case actionTypes.SET_ADMIN_PRODUCT_COMBO:
            return {
                ...state,
                adminProductCombo: [action.adminProductCombo]
            };
        case actionTypes.SET_ADMIN_RATE_COMBO:
            return {
                ...state,
                adminRateCombo: [action.adminRateCombo]
            };
        case actionTypes.SET_ADMIN_VAT_COMBO:
            return {
                ...state,
                adminVatCombo: [action.adminVatCombo]
            };
        case actionTypes.SET_ADMIN_TAX_COMBO:
            return {
                ...state,
                adminTaxCombo: [action.adminTaxCombo]
            };
        case actionTypes.SET_ADMIN_COST_FEE_COMBO:
            return {
                ...state,
                adminCostFeeCombo: [action.adminCostFeeCombo]
            };
        case actionTypes.SET_ADMIN_SERVICE_COMBO:
            return {
                ...state,
                adminServiceCombo: [action.adminServiceCombo]
            };
        case actionTypes.SET_ADMIN_AGGR_PTR_COMBO:
            return {
                ...state,
                adminAggrPtrCombo: [action.adminAggrPtrCombo]
            };
        case actionTypes.SET_ADMIN_CATEGORY_COMBO:
            return {
                ...state,
                adminCategoryCombo: [action.adminCategoryCombo]
            };
        case actionTypes.SET_ADMIN_PTR_TYPE_COMBO:
            return {
                ...state,
                adminPtrTypeCombo: [action.adminPtrTypeCombo]
            };
        case actionTypes.SET_BILLS_PRODUCT_COMBO:
            return {
                ...state,
                billsProductCombo: [action.billsProductCombo]
            };
        case actionTypes.SET_BILLS_CHANNEL_COMBO:
            return {
                ...state,
                billsChannelCombo: [action.billsChannelCombo]
            };
        case actionTypes.SET_ONLINE_PARTNER_COMBO:
            return {
                ...state,
                onlinePartnerCombo: [action.onlinePartnerCombo]
            };
        case actionTypes.SET_ONLINE_BRANCH_COMBO:
            return {
                ...state,
                onlineBranchCombo: [action.onlineBranchCombo]
            };
        case actionTypes.SET_TOPUP_PARTNER_COMBO:
            return {
                ...state,
                topupPartnerCombo: [action.topupPartnerCombo]
            };
        case actionTypes.SET_TOPUP_BRANCH_COMBO:
            return {
                ...state,
                topupBranchCombo: [action.topupBranchCombo]
            };
        case actionTypes.SET_TOPUP_PROVIDER_COMBO:
            return {
                ...state,
                topupProviderCombo: [action.topupProviderCombo]
            };
        case actionTypes.SET_CASH_IN_PRODUCT_COMBO:
            return {
                ...state,
                cashinProductCombo: [action.cashinProductCombo]
            };
        default:
            return state;
    }
};

export default comboboxReducer;