import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import cloakReducer from './cloakReducer';
import datetimeReducer from './datetimeReducer';
import misReducer from './misReducer';
import comboReducer from './comboboxReducer';
import popAlertReducer from './popAlertReducer';
import autoloadReducer from './autoloadReducer';
import dynamicReducer from './extraCaseReducer';
import loadingButtonReducer from './loadingReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    cloak: cloakReducer,
    datetimeFilter: datetimeReducer,
    miscellaneousFilter: misReducer,
    comboboxReducer: comboReducer,
    alertReducer: popAlertReducer,
    autoloadAction: autoloadReducer,
    dynamicReducer,
    loadingButtonReducer,
});

export default reducer;
