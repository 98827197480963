import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { updateAction } from "store/action/popAlert";

const ActionButton = ({ action }) => {
    const dispatch = useDispatch();
    const [buttonAction, setButtonAction] = useState(action);
    useEffect(() => {
        let isMounted = true;
        setButtonAction(action);
        return () => {
            isMounted = false;
        };
    }, [action]);

    const dispatchChanges = (value, name) => {
        const newAction = buttonAction?.map(data => {
            if (name === data?.name) {
                return {
                    ...data,
                    value: !value,
                };
            }
            return data;
        });
        dispatch(updateAction(newAction));
    };

    return (
        <>
            <Stack spacing={1} direction="row" justifyContent={buttonAction?.length >= 2 ? "end" : "center"}>
            {
                buttonAction?.map((data, index) => (
                    <LoadingButton
                        key={`action-btn-${index}`}
                        onClick={() => { dispatchChanges(data?.value, data?.name); }}
                    >
                        {data?.name}
                    </LoadingButton>
                ))
            }
            </Stack>
        </>
    );
};

export default ActionButton;