import dashboard from './Dashboards/dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import payments from './payments';
import inventory from './inventory';
import transactions from './transactions';
import reports from './reports';
import dashSingle from './Dashboards/dashSingle';
import dashGroup from './Dashboards/dashGroup';
import dashCollapse from './Dashboards/dashCollapse';
import transactionReportGrp from './TransactionReport/transactionReportGrp';
import transactionReportCol from './TransactionReport/transactionReportCol';
import reportGrp from './Reports/reportGrp';
import reportCol from './Reports/reportCol';
import partnerGrp from './PartnerProfile/partnerGrp';
import partnerCol from './PartnerProfile/partnerCol';
import adminGrp from './Admin/adminGrp';
import adminCol from './Admin/adminCol';
import maintenanceGrp from './Maintenance/maintenanceGrp';
import maintenanceCol from './Maintenance/maintenanceCol';
import financialGrp from './Financial/financialGrp';
import financialCol from './Financial/financialCol';
import rewardsGrp from './Rewards/rwdGrp';
import rewardsCol from './Rewards/rwdCol';
import settlementGrp from './Settlement/stlntGrp';
import settlementCol from './Settlement/stlntCol';
import serviceHealthMenu from './ServiceHealth/serviceHealthMenu';
// ==============================|| MENU ITEMS ||============================== //
// [dashboard, inventory, utilities, other, payments]
const menuItems = {
    items: [
        dashSingle,
        dashGroup,
        dashCollapse,
        transactionReportGrp,
        transactionReportCol,
        reportGrp,
        reportCol,
        partnerGrp,
        partnerCol,
        financialGrp,
        financialCol,
        rewardsGrp,
        rewardsCol,
        settlementGrp,
        settlementCol,
        adminGrp,
        adminCol,
        maintenanceGrp,
        maintenanceCol,
        serviceHealthMenu,
    ]
};

export default menuItems;
