// assets
import { IconCup } from '@tabler/icons';

// constant
const icons = { IconCup };

// ==============================|| DASHBOARD CHILD MENU ITEMS ||============================== //

const dashChildSoda = {
        id: `dashboard-${Math.random()}`,
        title: 'Soda',
        type: 'item',
        url: '/dashboard/soda',
        icon: icons.IconCup,
        breadcrumbs: false,
        children: [],
        role: process.env.REACT_APP_RL_CH_DASHSODA
    };

export default dashChildSoda;