const topUp = {
    id: `topUp-${Math.random()}`,
    title: 'Topup',
    type: 'item',
    url: `/transaction/top`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_TRANSTOP
};

export default topUp;