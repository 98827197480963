// assets
import { IconReport } from '@tabler/icons';
import mntChild from './MntChild';

// constant
const icons = { IconReport };

const maintenanceCol = {
    id: `maintenanceCol-${Math.random()}`,
    title: 'Maintenance',
    type: 'collapse',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: mntChild,
    role: process.env.REACT_APP_RL_MNTCOL
};

export default maintenanceCol;