import { HIDE_ALERT, SHOW_ALERT, UPDATE_ACTION } from "store/actions";
import { alertInitialState } from "store/popAlertReducer";

const initialState = alertInitialState;
export const showAlert = ({
    pop = true,
    transactionDate = initialState.transactionDate,
    amount = initialState.amount,
    message = initialState.message,
    title = initialState.title,
    action = initialState.action,
    type = initialState.type,
    from = initialState.from,
    proceed = initialState.proceed
}) => ({
    type: SHOW_ALERT,
    payload: { pop, transactionDate, amount, message, title, action, type, from, proceed }
});

export const hideAlert = () => ({
    type: HIDE_ALERT
});

export const updateAction = (action) => ({
    type: UPDATE_ACTION,
    payload: { action }
});