const billsPayment = {
    id: `billsPayment-${Math.random()}`,
    title: 'Bills Payment',
    type: 'item',
    url: `/transaction/bills`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_TRANSBILLS
};

export default billsPayment;