import { Navigate } from "react-router-dom";

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const MiscellaneousRoutes = {
    path: '*',
    element: [<Navigate to="/" replace />]
};

export default MiscellaneousRoutes;
