import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import Loadable from 'ui-component/Loadable';
// sample page routing
const BlankPage = Loadable(lazy(() => import('views/blank-page')));
const OpFund = Loadable(lazy(() => import('views/financial/op-fund')));
const PartnerPrefund = Loadable(lazy(() => import('views/financial/partner-prefunding')));
const PartnerFundAllocation = Loadable(lazy(() => import('views/financial/partner-fund-allocation')));
const PartnerWallet = Loadable(lazy(() => import('views/financial/partner-wallet')));

// ==============================|| TRANSACTION REPORT ROUTING ||============================== //

const FinancialRoutes = {
    path: '/financial',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_FIN}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        // {
        //     path: '/financial',
        //     element: [
        //         <></>
        //     ]
        // },
        {
            path: '/financial/partnerPrefund',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_FINANCIAL_MENU_CHILD_PARTNERPREFUND}`}>
                    <PartnerPrefund key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/financial/partnerFundAllocation',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_FINANCIAL_MENU_PARTNERALLOCATIONFUND}`}>
                    <PartnerFundAllocation key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/financial/partnerWallet',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_FINANCIAL_MENU_PARTNERWALLET}`}>
                    <PartnerWallet />
                </PrivateRoutes>
            ]
        },
        {
            path: '/financial/opFund',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_FINANCIAL_MENU_CHILD_OPFUND}`}>
                    <OpFund key={Math.random()} />
                </PrivateRoutes>
            ]
        },
    ]
};

export default FinancialRoutes;