// assets
import { IconReport } from '@tabler/icons';
import rwdChild from './RwdChild';

// constant
const icons = { IconReport };

const rewardsGrp = {
    id: `rewardsGrp-${Math.random()}`,
    title: 'Rewards',
    type: 'group',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: rwdChild,
    role: process.env.REACT_APP_RL_RWDGRP
};

export default rewardsGrp;
