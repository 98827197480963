import { SET_MIS_SN, SET_MIS_STATUS, SET_MIS_TRANSTYPE, SET_CUSTOM_CHECKBOX } from "./actions";

export const initialState = {
    misSerialNo: null,
    misStatus: null,
    misTransType: null,
    misCusCheck: true,
};

// ==============================|| Miscellaneous Filer REDUCER ||============================== //

const misReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MIS_SN:
            return {
                ...state,
                misSerialNo: action.misSerialNo
            };
        case SET_MIS_STATUS:
            return {
                ...state,
                misStatus: action.misStatus
            };
        case SET_MIS_TRANSTYPE:
            return {
                ...state,
                misTransType: action.misTransType
            };
        case SET_CUSTOM_CHECKBOX:
            return {
                ...state,
                misCusCheck: action.misCusCheck
            };
        default:
            return state;
    }
};
export default misReducer;