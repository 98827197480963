// action - state management
import * as actionTypes from './actions';

export const initialState = {
    loadingModal: false,
};

// ==============================|| CLOAK REDUCER ||============================== //

const loadingButtonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_MODAL:
            return {
                ...state,
                loadingModal: action.loadingModalFlg
            };
        default:
            return state;
    }
};

export default loadingButtonReducer;