const partnerWallet = {
    id: `partnerWallet-${Math.random()}`,
    title: 'Partner Wallet',
    type: 'item',
    url: `/financial/partnerWallet`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_FINANCIAL_MENU_PARTNERWALLET
};

export default partnerWallet;