import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import { element } from 'prop-types';
import Loadable from 'ui-component/Loadable';

const BlankPage = Loadable(lazy(() => import('views/blank-page')));

// ==============================|| DEFAULT ROUTING ||============================== //

const PartnerProfileRoutes = {
    path: '/partner',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_PRT}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/partner',
            element: [
                <></>
            ]
        },
        {
            path: '/partner/biller',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_PRTPRFBIL}`}>
                    <BlankPage key={Math.random()} />
                </PrivateRoutes>
            ]
        },
    ]
};

export default PartnerProfileRoutes;