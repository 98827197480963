// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashSingle = {
    id: `dashboard-${Math.random()}`,
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard/default',
    icon: icons.IconDashboard,
    breadcrumbs: false,
    children: [],
    role: process.env.REACT_APP_RL_DASHSINGLE
};

export default dashSingle;