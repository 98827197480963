// assets
import { IconFile } from '@tabler/icons';
import genChild from './RepChild';

// constant
const icons = { IconFile };

const generateReportCol = {
    id: `generateReportCol-${Math.random()}`,
    title: 'Reports',
    type: 'collapse',
    // url: `/dashboard/default`,
    icon: icons.IconFile,
    breadcrumbs: false,
    children: genChild,
    role: process.env.REACT_APP_RL_GENCOL
};

export default generateReportCol;