const mntUsers = {
    id: `mntUsers-${Math.random()}`,
    title: 'Users',
    type: 'item',
    url: `/maintenance/users`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_MNTUSR
};

export default mntUsers;