// assets
import { IconReport } from '@tabler/icons';
import transChild from './TransChild';

// constant
const icons = { IconReport };

const transactionReportCol = {
    id: `transactionReportcollaps-${Math.random()}`,
    title: 'Transaction Report',
    type: 'collapse',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: transChild,
    role: process.env.REACT_APP_RL_TRANSCOL
};

export default transactionReportCol;