// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import checker from 'utils/checker';
import keycloak from 'services/keycloak';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return checker.authByRoles(keycloak.token, [item.role]) ? <NavGroup key={item.id} item={item} /> : <div key={item.id} />;
            case 'item':
                return checker.authByRoles(keycloak.token, [item.role]) ? <NavItem key={item.id} item={item} level={1} /> : <div key={item.id} />;
            case 'collapse':
                return checker.authByRoles(keycloak.token, [item.role]) ? <NavCollapse key={item.id} menu={item} level={1} /> : <div key={item.id} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
