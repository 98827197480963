const partnerProduct = {
    id: `partnerProduct-${Math.random()}`,
    title: 'Partner Product',
    type: 'item',
    url: `/admin/partner/product`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_PRTPRFCHL
};

export default partnerProduct;