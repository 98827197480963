import rateScheme from "./rateScheme";
import wallet from "./wallet";
import partner from "./partner";
import product from "./product";
import partnerProduct from "./partnerProduct";
import genQr from "./generateQr";

const admChild = [
    genQr,
    partner,
    product,
    partnerProduct,
    rateScheme,
    wallet
];

export default admChild;