import billsPayment from "./billsPayment";
import cashIn from "./cashIn";
import disbursement from "./disbursement";
import onlinePayment from "./onlinePayment";
import ordering from "./ordering";
import topUp from "./topUp";

const transChild = [
    onlinePayment,
    ordering,
    billsPayment,
    disbursement,
    cashIn,
    topUp
];

export default transChild;