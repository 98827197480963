import { useCallback, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router';

/**
 * Returns the auth info and some auth strategies.
 *
 */
export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const [user, setUser] = useState({});
  const [typeMenu, setTypeMenu] = useState([]);

  // fetch user profile
  useEffect(() => {
    if (initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const userProfile = await keycloak.loadUserProfile();

        setUser({ ...userProfile, fullName: `${userProfile.firstName} ${userProfile.lastName}` });
      } catch (err) {
          console.log("error fetchuser", err);
      }
    };
    const settingTypeMenu = () => {
      const roles = keycloak.realmAccess.roles;
      const createMenu = [];
      for (let i = 0; i < roles.length; i += 1) {
        if (process.env.REACT_APP_BILLER_TYPE === roles[i]) createMenu.push({ title: 'Biller Report', inputValue: '/biller_report' });
        if (process.env.REACT_APP_MERCHANT_TYPE === roles[i]) createMenu.push({ title: 'Merchant Report', inputValue: '/merchant_report' });
        if (process.env.REACT_APP_APOLLO_TYPE === roles[i]) createMenu.push({ title: 'Apollo', inputValue: '/apollo_report' });
      }
      setTypeMenu(createMenu);
    };

    if (keycloak.authenticated) {
      fetchUserInfo();
      settingTypeMenu();
    }
  }, [keycloak, initialized]);

  return {
    isAuthenticated: !!keycloak.authenticated,
    initialized,
    meta: {
      keycloak,
    },
    token: keycloak.token,
    user,
    roles: keycloak.realmAccess,
    parse: keycloak.tokenParsed,
    logout: useCallback(() => { keycloak.logout(); localStorage.clear(); <Navigate to="/" />; }, [keycloak]),
    typeMenu
  };
};

export default {
  useAuth,
};