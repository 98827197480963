const disbursement = {
    id: `disbursement-${Math.random()}`,
    title: 'Disbursement',
    type: 'item',
    url: `/transaction/disbursement`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_TRANSDISBURSE
};

export default disbursement;