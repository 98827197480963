import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import { element } from 'prop-types';
import Loadable from 'ui-component/Loadable';

const Report = Loadable(lazy(() => import('views/settlement/report')));

// ==============================|| DEFAULT ROUTING ||============================== //

const RewardsRoutes = {
    path: '/settlement',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_STL}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/settlement',
            element: [<></>]
        },
        {
            path: '/settlement/report',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_SETTLEMENTCH}`}>
                    <Report />
                </PrivateRoutes>
            ]
        }
    ]
};

export default RewardsRoutes;
