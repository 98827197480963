// assets
import { IconDashboard } from '@tabler/icons';
import dashChilds from './DashBoardChilds';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashGroup = {
    id: `dashboard-${Math.random()}`,
    title: 'Dashboard',
    type: 'group',
    // url: `/dashboard/default`,
    // icon: icons.IconDashboard,
    breadcrumbs: false,
    children: dashChilds,
    role: process.env.REACT_APP_RL_DASHGRP
};

export default dashGroup;