const rateScheme = {
    id: `rateScheme-${Math.random()}`,
    title: 'Rate Scheme',
    type: 'item',
    url: `/admin/rate`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_ADMRSM
};

export default rateScheme;