const channel = {
    id: `channel-${Math.random()}`,
    title: 'Channel',
    type: 'item',
    url: `/partner/channel`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_PRTPRFCHL
};

export default channel;