const wallet = {
    id: `wallet-${Math.random()}`,
    title: 'Wallet',
    type: 'item',
    url: `/admin/wallet`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_ADMWAL
};

export default wallet;