// assets
import { IconDashboard } from '@tabler/icons';
import dashChilds from './DashBoardChilds';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashCollapse = {
    id: `dashboard-${Math.random()}`,
    title: 'Dashboard',
    type: 'collapse',
    // url: '/dashboard/default',
    // icon: icons.IconDashboard,
    breadcrumbs: false,
    children: dashChilds,
    role: process.env.REACT_APP_RL_DASHCOL
};

export default dashCollapse;