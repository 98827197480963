import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducer';
import thunk from 'redux-thunk';

// ==============================|| REDUX - MAIN STORE ||============================== //
const middleWares = [thunk];
const initialState = {};
const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleWares))
);
const persister = 'Free';

export { store, persister };