const partnerFundAllocation = {
    id: `partnerFundAllocation-${Math.random()}`,
    title: 'Partner Fund',
    type: 'item',
    url: `/financial/partnerFundAllocation`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_FINANCIAL_MENU_PARTNERALLOCATIONFUND
};

export default partnerFundAllocation;