const partner = {
    id: `partner-${Math.random()}`,
    title: 'Partner',
    type: 'item',
    url: `/admin/partner`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_PRTPRFCHL
};

export default partner;