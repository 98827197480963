const product = {
    id: `product-${Math.random()}`,
    title: 'Product',
    type: 'item',
    url: `/admin/product`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_PRTPRFCHL
};

export default product;