import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import Loadable from 'ui-component/Loadable';
// sample page routing
const BlankPage = Loadable(lazy(() => import('views/blank-page')));
const Rate = Loadable(lazy(() => import('views/admin/rate-scheme')));
const Partner = Loadable(lazy(() => import('views/admin/partner')));
const Product = Loadable(lazy(() => import('views/admin/product')));
const PartnerProduct = Loadable(lazy(() => import('views/admin/partner-product')));
const GenQr = Loadable(lazy(() => import('views/admin/genqr-reset-password')));

// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/admin',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_ADN}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/admin',
            element: [
                <></>
            ]
        },
        {
            path: '/admin/generate-qr',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_ADMNGQRPW}`}>
                    <GenQr key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/admin/partner',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_PRTPRFCHL}`}>
                    <Partner key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/admin/product',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_PRTPRFCHL}`}>
                    <Product key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/admin/partner/product',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_PRTPRFCHL}`}>
                    <PartnerProduct key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/admin/rate',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_ADMRSM}`}>
                    <Rate key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/admin/wallet',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_ADMWAL}`}>
                    <BlankPage key={Math.random()} />
                </PrivateRoutes>
            ]
        },
    ]
};

export default AdminRoutes;
