import ActionButton from "./action-button";

const { Stack, Typography, Divider, Card, CardContent } = require("@mui/material");
const { useDispatch, useSelector } = require("react-redux");

const DefaultAlert = ({ pop, transactionDate, amount, message, title, action, type }) => {
    const dispatch = useDispatch();
    return (
        <Stack spacing={1} direction="column" sx={{ background: "#FFD238" }}>
            <Typography fontSize="16px" fontWeight="600" padding="8px 10px 3px 10px">{title}</Typography>
            <Stack width="inherit" sx={{ background: "#fff" }} spacing={1} direction="column" padding="5px 10px 10px 10px">
                <Card>
                    <CardContent>
                        {
                            amount ?
                            <Typography fontSize="15px" fontWeight="bold">{amount}<br /><br /></Typography>
                            : <></>
                        }
                        {
                            transactionDate ?
                            <Typography fontSize="15px" fontWeight="bold">{transactionDate}<br /><br /></Typography>
                            : <></>
                        }
                        <Typography fontSize="15px">{message}</Typography>
                    </CardContent>
                </Card>
                <ActionButton action={action} />
            </Stack>
        </Stack>
    );
};

export default DefaultAlert;