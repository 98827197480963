// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_TYPO_FONT_SIZE = '@customization/SET_TYPO_FONT_SIZE';
export const SET_TEXT_FIELD_FONT_SIZE = '@customization/SET_TEXT_FIELD_FONT_SIZE';
export const SET_TEXT_BUTTON_FONT_SIZE = '@customization/SET_TEXT_BUTTON_FONT_SIZE';
export const SET_PRE_COLOR = '@customization/SET_PRE_COLOR';
// action - cloak reducer
export const SET_CLOAK = '@cloak/SET_CLOAK';
// action date and time spec filter
export const SET_DATE_FROM = '@sdatetime/SET_DATE_FROM';
export const SET_TIME_FROM = '@sdatetime/SET_TIME_FROM';
export const SET_DATE_TO = '@sdatetime/SET_DATE_TO';
export const SET_TIME_TO = '@sdatetime/SET_TIME_TO';
// action miscellaneous filters
export const SET_MIS_STATUS = '@misfil/SET_MIS_STATUS';
export const SET_MIS_SN = '@misfil/SET_MIS_SN';
export const SET_MIS_TRANSTYPE = '@misfil/SET_MIS_TRANSTYPE';
// action comboboxes filters
export const SET_PRODUCT_COMBO = '@combox/SET_PRODUCT_COMBO';
export const SET_STATUS_COMBO = '@combox/SET_STATUS_COMBO';
export const SET_CHANNEL_COMBO = '@combox/SET_CHANNEL_COMBO';
export const SET_CATEGORY_COMBO = '@combox/SET_CATEGORY_COMBO';
export const SET_ADMIN_PTR_TYPE_COMBO = '@combox/SET_ADMIN_PTR_TYPE_COMBO';
export const SET_ADMIN_BRANCH_COMBO = '@combox/SET_ADMIN_BRANCH_COMBO';
export const SET_ADMIN_PRODUCT_COMBO = '@combox/SET_ADMIN_PRODUCT_COMBO';
export const SET_ADMIN_RATE_COMBO = '@combox/SET_ADMIN_RATE_COMBO';
export const SET_ADMIN_VAT_COMBO = '@combox/SET_ADMIN_VAT_COMBO';
export const SET_ADMIN_TAX_COMBO = '@combox/SET_ADMIN_TAX_COMBO';
export const SET_ADMIN_COST_FEE_COMBO = '@combox/SET_ADMIN_COST_FEE_COMBO';
export const SET_ADMIN_SERVICE_COMBO = '@combox/SET_ADMIN_SERVICE_COMBO';
export const SET_ADMIN_AGGR_PTR_COMBO = '@combox/SET_ADMIN_AGGR_PTR_COMBO';
export const SET_ADMIN_CATEGORY_COMBO = '@combox/SET_ADMIN_CATEGORY_COMBO';
export const SET_BILLS_PRODUCT_COMBO = '@combox/SET_BILLS_PRODUCT_COMBO';
export const SET_BILLS_CHANNEL_COMBO = '@combox/SET_BILLS_CHANNEL_COMBO';
export const SET_ONLINE_PARTNER_COMBO = '@combox/SET_ONLINE_PARTNER_COMBO';
export const SET_ONLINE_BRANCH_COMBO = '@combox/SET_ONLINE_BRANCH_COMBO';
export const SET_TOPUP_PARTNER_COMBO = '@combox/SET_TOPUP_PARTNER_COMBO';
export const SET_TOPUP_BRANCH_COMBO = '@combox/SET_TOPUP_BRANCH_COMBO';
export const SET_TOPUP_PROVIDER_COMBO = '@combox/SET_TOPUP_PROVIDER_COMBO';
export const SET_CASH_IN_PRODUCT_COMBO = '@combox/SET_CASH_IN_PRODUCT_COMBO';

// action pop alerts
export const SHOW_ALERT = '@pop/SHOW_ALERT';
export const HIDE_ALERT = '@pop/HIDE_ALERT';
export const UPDATE_ACTION = '@pop/UPDATE_ACTION';

// loading button flag
export const LOADING_MODAL = '@pop/LOADING_MODAL';

// setting a function
export const SET_ACTION = '@action/SET_ACTION';

// autoload action
export const SET_AUTOLOAD_ACTION = '@filter/SET_AUTOLOAD_ACTION';
export const SET_AUTOLOAD_INTERVAL = '@filter/SET_AUTOLOAD_ACTION';

// checkbox
export const SET_CUSTOM_CHECKBOX = '@checkbox/SET_CUSTOM_CHECKBOX';

// holder
export const SET_HOLDER = '@holder/SET_HOLDER';