import moment from 'moment';
// action - state management
import { SET_DATE_FROM, SET_TIME_FROM, SET_DATE_TO, SET_TIME_TO, } from "./actions";

export const initialState = {
    dateFrom: moment().format('YYYY-MM-DD'),
    timeFrom: '00:00:00',
    dateTo: moment().format('YYYY-MM-DD'),
    timeTo: '23:59:59',
};

// ==============================|| CLOAK REDUCER ||============================== //

const datetimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATE_FROM:
            return {
                ...state,
                dateFrom: action.dateFrom
            };
        case SET_TIME_FROM:
            return {
                ...state,
                timeFrom: action.timeFrom
            };
        case SET_DATE_TO:
            return {
                ...state,
                dateTo: action.dateTo
            };
        case SET_TIME_TO:
            return {
                ...state,
                timeTo: action.timeTo
            };
        default:
            return state;
    }
};
export default datetimeReducer;