const onlinePayment = {
    id: `onlinePayment-${Math.random()}`,
    title: 'Online Payment',
    type: 'item',
    url: `/transaction/online`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_TRANSONLINE
};

export default onlinePayment;