import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';

// ==============================|| DEFAULT ROUTING ||============================== //

const DefaultRoutes = {
    path: '/',
    element: [
        <PrivateRoutes key={Math.random()}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: []
};

export default DefaultRoutes;