import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';
import checker from "utils/checker";

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses,
    IconButton
} from '@mui/material';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { useEffect, useState } from 'react';
// material-ui
import AnimateButton from 'ui-component/extended/AnimateButton';
import { IconRefreshDot } from '@tabler/icons';
import { SET_CLOAK } from 'store/actions';

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            Progress
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const { keycloak } = useKeycloak();
    const theme = useTheme();

    const logout = useAuth();
    const [interactTimer, setInteractTimer] = useState(moment().add(30, 's').unix());
    const check = keycloak.tokenParsed;
    const totalTime = check.exp - check.iat;
    const [initialExpTimeRem, setInitialExpTimeRem] = useState(check.exp);

    const sessionFlg = checker.authByRoles(useAuth().token, [process.env.REACT_APP_RL_SESSIONEXPIRATIONFLG]);

    const calculateTimeLeft = () => {
        let timeLeft = {};
        const expRemTime = ((initialExpTimeRem - moment().unix()) - 34) * 1000;
        let intRemTime = interactTimer - moment().unix();

        if (intRemTime <= 0) {
            intRemTime = 0;
        }

        if (intRemTime !== 0 && expRemTime <= 1) {
            setInitialExpTimeRem((moment().add(15, 'minutes').unix()));
        }

        const mins = Math.floor((expRemTime / 1000 / 60) % 60);
        const sec = Math.floor((expRemTime / 1000) % 60);
        const totlSec = Math.floor((expRemTime / 1000));

        timeLeft = {
            minutes: mins < 0 ? 0 : mins,
            seconds: sec < 0 ? 0 : sec,
            totalSeconds: totlSec < 0 ? 0 : totlSec,
            intRemTime,
            progress: (100 * totlSec) / totalTime < 0 ? 0 : (100 * totlSec) / totalTime
        };

        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        try {
            if (localStorage.getItem("out")) {
                logout.logout();
            }
        } catch (error) {
            // do nothing
        }
    }, []);
    useEffect(() => {
        if (sessionFlg) {
            if ((timeLeft.totalSeconds >= 1) || (timeLeft.intRemTime >= 1)) {
                setTimeout(() => {
                    setTimeLeft(calculateTimeLeft());
                }, 1000);
            } else {
                localStorage.setItem("out", true);
                alert("Session Expired, No interaction has been detected!");
                <Navigate to="/" />;
                window.location.reload();
            }
        }
    }, [timeLeft]);
    const handleEvent = (e) => {
        if (interactTimer < moment().add(30, 's').unix()) {
            setInteractTimer(moment().add(30, 's').unix());
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleEvent, true);
        document.addEventListener("click", handleEvent, true);
        document.addEventListener("touchend", handleEvent, true);

        return () => {
            document.removeEventListener("keydown", handleEvent, true);
            document.removeEventListener("click", handleEvent, true);
            document.removeEventListener("touchend", handleEvent, true);
        };
    });

    return (
        <CardStyle>
            {
                sessionFlg ?

                <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemAvatar sx={{ mt: 0 }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    color: theme.palette.primary.main,
                                    border: 'none',
                                    borderColor: theme.palette.primary.main,
                                    background: '#fff',
                                    marginRight: '12px'
                                }}
                            >
                                <AnimateButton type="rotate" rotation={-360}>
                                    <IconButton color={timeLeft.intRemTime > 0 ? "inherit" : "error"} size="large" disableRipple>
                                        <IconRefreshDot />
                                    </IconButton>
                                </AnimateButton>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                    Session Expire Time
                                </Typography>
                            }
                            secondary={<Typography variant="caption">{(totalTime / 60) % 60} / {`${timeLeft.minutes}:${timeLeft.seconds}`} min(s)</Typography>}
                        />
                    </ListItem>
                </List>
                <LinearProgressWithLabel value={timeLeft.progress} />
                </CardContent>
            : <></>
            }

        </CardStyle>
    );
};

export default MenuCard;
