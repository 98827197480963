import { SHOW_ALERT, HIDE_ALERT, UPDATE_ACTION } from "./actions";

export const alertActionDefault = [
    {
        name: 'Close',
        value: false,
        sequence: 100
    }
];
export const alertInitialState = {
    pop: false,
    transactionDate: '',
    amount: '',
    message: '',
    title: 'System Message',
    type: 'default',
    action: alertActionDefault,
    from: null,
    proceed: false
};

// ==============================|| POP ALERT REDUCER ||============================== //
const alertReducer = (state = alertInitialState, action) => {
    switch (action.type) {
        case SHOW_ALERT:
            return {
                ...state,
                pop: action.payload.pop,
                transactionDate: action.payload.transactionDate,
                amount: action.payload.amount,
                message: action.payload.message,
                title: action.payload.title,
                type: action.payload.type,
                action: action.payload.action,
                from: action.payload.from,
                proceed: action.payload.proceed,
            };
        case UPDATE_ACTION:
            return {
                ...state,
                action: action.payload.action
            };
        case HIDE_ALERT:
            return alertInitialState;
        default:
            return state;
    }
};

export default alertReducer;