// action - state management
import { SET_CLOAK } from "./actions";

export const initialState = {
    cloakIn: false,
};

// ==============================|| CLOAK REDUCER ||============================== //

const cloakReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLOAK:
            return {
                ...state,
                cloakIn: action.cloakIn
            };
        default:
            return state;
    }
};

export default cloakReducer;