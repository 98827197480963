import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Page404 from 'views/Page404';

// login option 1 routing
const Cloak = Loadable(lazy(() => import('views/pages/authentication/keycloak-auth/AuthLogin')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/cloak',
            element: <Cloak />
        },
        {
            path: '/404',
            element: <Page404 />
        }
    ]
};

export default AuthenticationRoutes;
