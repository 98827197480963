// assets
import { IconReport } from '@tabler/icons';
import finChild from './FinChild';

// constant
const icons = { IconReport };

const financialCol = {
    id: `financialColcollaps-${Math.random()}`,
    title: 'Financial',
    type: 'collapse',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: finChild,
    role: process.env.REACT_APP_FINANCIAL_MENU_COL
};

export default financialCol;