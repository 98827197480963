import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, CircularProgress } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from 'services/keycloak';
import AlertMessage from 'commons/pop-messages/AlertMessage';
// ==============================|| APP ||============================== //

const keycloakProviderInitConfig = {
    onload: 'login-required'
};

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <ReactKeycloakProvider
                    authClient={keycloak}
                    initOptions={keycloakProviderInitConfig}
                    LoadingComponent={
                        <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
                            <CircularProgress size="large" title="Loading..." />
                        </div>
                    }
                >
                    <NavigationScroll>
                        <Routes />
                        <AlertMessage />
                    </NavigationScroll>
                </ReactKeycloakProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
