import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import Loadable from 'ui-component/Loadable';
// sample page routing
const BlankPage = Loadable(lazy(() => import('views/blank-page')));
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const NewDefault = Loadable(lazy(() => import('views/dashboard/New')));
// ==============================|| DASHBOARD ROUTING ||============================== //

const DashboardRoutes = {
    path: '/',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_DSD}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/', // main dashboard ui here
            element: [
                <PrivateRoutes key={Math.random()} accessRole="">
                    <NewDefault key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        {
            path: '/dashboard/default', // main dashboard ui here
            element: [
                <PrivateRoutes key={Math.random()} accessRole="">
                    <NewDefault key={Math.random()} />
                </PrivateRoutes>
            ]
        },
        // {
        //     path: '/dashboard/newdefault', // main dashboard ui here
        //     element: [
        //         <PrivateRoutes key={Math.random()} accessRole="">
        //             <NewDefault key={Math.random()} />
        //         </PrivateRoutes>
        //     ]
        // },
    ]
};

export default DashboardRoutes;