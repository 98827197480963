const mntAuditTrail = {
    id: `mntAuditTrail-${Math.random()}`,
    title: 'Audit Trail',
    type: 'item',
    url: `/maintenance/audit`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_MNTAUT
};

export default mntAuditTrail;