const ordering = {
    id: `onlinePayment-${Math.random()}`,
    title: 'Ordering',
    type: 'item',
    url: `/transaction/ordering`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_TRANSORDERING
};

export default ordering;