import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import { element } from 'prop-types';
import Loadable from 'ui-component/Loadable';

const Voucher = Loadable(lazy(() => import('views/rewards/voucher')));

// ==============================|| DEFAULT ROUTING ||============================== //

const RewardsRoutes = {
    path: '/rewards',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_STL}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/rewards',
            element: [<></>]
        },
        {
            path: '/rewards/voucher',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_RWDVCH}`}>
                    <Voucher />
                </PrivateRoutes>
            ]
        }
    ]
};

export default RewardsRoutes;
