// assets
import { IconReport } from '@tabler/icons';
import mntChild from './MntChild';

// constant
const icons = { IconReport };

const maintenanceGrp = {
    id: `maintenanceGrp-${Math.random()}`,
    title: 'Maintenance',
    type: 'group',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: mntChild,
    role: process.env.REACT_APP_RL_MNTGRP
};

export default maintenanceGrp;