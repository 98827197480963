import opFund from "./opFund";
import partnerFundAllocation from "./partnerFundAllocation";
import partnerPrefunding from "./partnerPrefunding";
import partnerWallet from "./partnerWallet";

const finChild = [
    partnerPrefunding,
    partnerFundAllocation,
    partnerWallet,
    opFund,
];

export default finChild;