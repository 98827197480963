// assets
import { IconReport } from '@tabler/icons';
import stlntChild from './StlntChild';

// constant
const icons = { IconReport };

const settlementCol = {
    id: `settlementCol-${Math.random()}`,
    title: 'Settlement',
    type: 'collapse',
    // url: `/dashboard/default`,
    icon: icons.IconReport,
    breadcrumbs: false,
    children: stlntChild,
    role: process.env.REACT_APP_RL_SETTLEMENTCOL
};

export default settlementCol;
