// assets
import { IconDashboard } from '@tabler/icons';
import dashChildSoda from './dashChildSoda';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD CHILD MENU ITEMS ||============================== //

const dashChilds = [
    {
        id: `dashboard-${Math.random()}`,
        title: 'Default',
        type: 'item',
        url: '/dashboard/default',
        icon: icons.IconDashboard,
        breadcrumbs: false,
        children: [],
        role: process.env.REACT_APP_RL_CH_DASHDEFT
    },
    dashChildSoda
];

export default dashChilds;